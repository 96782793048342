.app {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(1.1em); 
  color: white;
  padding-bottom: 120px;
  
}

.app .menu-item {
  cursor: pointer;
}

.app .menu-item li {
  margin-top: 10px;
  border-radius: 5px;
}

.app .menu-item .list-group-item {
  margin-top: 10px;
  border-radius: 5px;
}
 
a.menu-item, a.menu-item:hover, a.menu-item:link, a.menu-item:visited, a.menu-item:focus, a.menu-item:active {
  outline: none;
  text-decoration: none;
  color: black!important;
} 

a.page, a.page:hover, a.page:link, a.page:visited, a.page:focus, a.page:active {
  outline: none;
  text-decoration: none;
  color: inherit!important;
}

.select {
  -webkit-touch-callout: unset; /* iOS Safari */
    -webkit-user-select: unset; /* Safari */
     -khtml-user-select: unset; /* Konqueror HTML */
       -moz-user-select: unset; /* Old versions of Firefox */
        -ms-user-select: unset; /* Internet Explorer/Edge */
            user-select: unset; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}


/*
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
} */