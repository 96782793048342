.card-img-top {
    width: 100%;
    height: 15vw;
    object-fit: cover;
}

a.card:link {
    color: inherit!important;
    text-decoration: none;
}

 a.card:visited {
    color: inherit!important;
    text-decoration: none;
}

a.card:hover {
    color: inherit!important;
    text-decoration: none;
}

a.card.bg-dark:hover {
    color: rgb(84, 150, 84)!important;
    filter: brightness(1.20);
    text-decoration: none;
    border-radius: 10px;
}

a.card:hover img {
    color: inherit!important;
    text-decoration: none;
   
}

a.card:active {
    color: inherit!important;
    text-decoration: none;
}

