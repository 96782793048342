body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
.heading {
  font-size: 24px; /* Default font size */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-size: 16px;
}

/* Font size for small screens */
@media (max-width: 576px) {
  body {
    font-size: 14px;
  }
  .heading {
    font-size: 14px; /* Default font size */
  }

}

/* Font size for medium screens */
@media (min-width: 577px) and (max-width: 768px) {
  body {
    font-size: 16px;
  }
  .heading {
    font-size: 16px; /* Default font size */
  }
}

/* Font size for large screens */
@media (min-width: 769px) {
  body {
    font-size: 18px;
  }
  .heading {
    font-size: 18px; /* Default font size */
  }
}

