.page-header .icon {
    font-size: 30px;
    padding-right: 10px;
}

.page-header .title {
    font-size: 1.2em;
}

.page-header .subtitle {
    margin-top: -10px;
    padding-left: 10px;
    font-size: .8em;
}

.page-header .dropdown-toggle::after {
  display: none !important;
  content: "" !important;
}

.page-header .btn-dark:focus, .btn:focus, .btn-dark:link , .btn:link, .btn-dark:hover , .btn:hover {
    outline: none;
    box-shadow: none;
}

.page-header {
    background-color: red;
}

.right-menu {
    padding-top: 45px;
}

.right-menu .btn {
    background-color: transparent;
    border-color: transparent;
}

.right-menu .btn-dark:focus {
    background-color: transparent;
    border-color: transparent;
}

.right-menu .btn-dark:hover {
    background-color: transparent;
    border-color: transparent;
}


@import url('https://fonts.googleapis.com/css?family=Pirata+One|Rubik:900');
/* logo section */
.logo {
  z-index: 0;
  display: flex;
  justify-content: left;
  padding-left: 0rem;
  align-items: left ;
  /* min-height: 60px; */
  /* min-height: 100vh; */
  /* background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important */
  /* background: linear-gradient(to right, #24243e, #141E30, #0f0c29);   */

}


.logo h1 {
  text-transform: Uppercase;
  margin-bottom: -0.25em; 
  font-family: 'Rubik', sans-serif;
  font-size: 2rem;
  color: #E4E5E6; 
}

.logo h1 {
  position: relative;
  background: linear-gradient(to right, #6868df, #1d4b9f, #240fe0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
}

.logo h1:before, h1:after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0; }

 .logo h1:before {
  z-index: -1;
  text-shadow: -0.02em -0.02em 2px rgba(255, 255, 255, 0.15)}
  
 .logo h1:after {
  z-index: -2;
  text-shadow: 5px 5px 5px rgba(0,0,0,.5), 15px 15px 15px rgba(0,0,0,.4), 25px 25px 25px rgba(0,0,0,.1);
  mix-blend-mode: multiply; 
} 
  
